import { getNumeralDeclension } from '@glow/shared/utils/format';

export function formatReviewCount(reviewCount: number) {
  const declension = getNumeralDeclension(reviewCount, [
    'отзыв',
    'отзыва',
    'отзывов',
  ]);
  return `${reviewCount} ${declension}`;
}
